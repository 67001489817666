import React, {useLayoutEffect} from 'react';
import './App.css';
import LoginPage from "./pages/login";
import {themeOptions} from "./themeOptions";
import {Container, createTheme, ThemeProvider} from "@mui/material";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {StoreProvider} from "./store/provider";
import {rootStore} from "./store/rootStore";
import MenuViewPage from "./pages/menuView";
import {reaction} from "mobx";
import {configureAuthAxios, configureUnauthHandler} from "./api/config";
import RecipePage from "./pages/recipe";
import GroceryListPage from "./pages/grocerylist";
import {QueryClient, QueryClientProvider} from "react-query";
import InfoPage from "./pages/info";
import SubManagePage from "./pages/submanage";
import YandexOauthPage from "./pages/oauth/yandex";

const theme = createTheme(themeOptions);
const router = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage/>,
    },
    {
        path: '/',
        element: <MenuViewPage/>
    },
    {
        path: '/recipe/:id',
        element: <RecipePage/>
    },
    {
        path: '/grocery-list',
        element: <GroceryListPage/>
    },
    {
        path: '/info',
        element: <InfoPage/>
    },
    {
        path: '/subscription',
        element: <SubManagePage/>
    },
    {
        path: '/oauth/yandex',
        element: <YandexOauthPage/>
    }
]);

reaction(
    () => rootStore.accountStore.accessToken,
    (token) => {
        if (!token) {
            router.navigate('/login');
        } else {
            configureAuthAxios(token);
        }
    }
);

const queryClient = new QueryClient();

function App() {
    useLayoutEffect(() => {
        if (rootStore.accountStore.accessToken === null) {
            if (router.state.location.pathname !== '/login' && !router.state.location.pathname.includes('/oauth/')) {
                router.navigate(`/login?redirectTo=${encodeURIComponent(window.location.href)}`);
            }

            return;
        }

        configureUnauthHandler(() => rootStore.accountStore.clearAccessToken());
        configureAuthAxios(rootStore.accountStore.accessToken);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <StoreProvider store={rootStore}>
                <ThemeProvider theme={theme}>
                    <Container className="App" sx={{p: 0}}>
                        <AnimatePresence>
                            <RouterProvider router={router}/>
                        </AnimatePresence>
                    </Container>
                </ThemeProvider>
            </StoreProvider>
        </QueryClientProvider>
    );
}

export default App;
