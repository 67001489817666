export interface Response<TResult> {
    success: boolean,
    errors: ResponseError[] | null,
    result: TResult | null
}

export interface ResponseError {
    code: string,
    userMessage: string,
    internalMessage: string
}

export const hasError = (resp: Response<any>, errorCode: string) => {
    return !resp.success && resp.errors!.some(e => e.code === errorCode);
}

export const ErrorCodes = {
    authCode: {
        accountNotFound: 'AUTHCODE_account_not_found',
        tooManyResends: 'AUTHCODE_too_many_resends'
    },
    generic: {
        unAuthorized: 'UNAUTH_GENERIC',
    }
};

export interface SignInResult {
    accessToken: string;
}

export interface PlanMenuWeekView {
    planId: number;
    days: Day[];
}

export interface Day {
    planId: number;
    date: string;
    recipes: DayRecipe[];
    daySummary: DaySummary;
}

export interface DayRecipe {
    id: number;
    recipeId: number;
    name: string;
    preview: string;
    portions: number;
    assignedTo: string;
    mealType: string;
    kcal: number;
    estimatedMinutes: number;
}

export interface DaySummary {
    kcal: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
}

export interface RecipeView {
    id: number;
    preview: string;
    name: string;
    estimatedMinutes: number;
    difficulty: string;
    sourceName?: string | null;
    sourceLink?: string | null;
    nutrientsByPortion?: RecipeViewNutrients | null;
    portions: number;
    originalPortions: number;
    ingredientCategories: RecipeViewIngredientCategory[];
    steps: RecipeViewStep[];
    advice?: string | null;
    author: string;
    recipeLink: string;
    useExternalRedirectToDetails: boolean;
    portionWeight: number;
}

export interface RecipeViewNutrients {
    calories: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
}

export interface RecipeViewIngredientCategory {
    name: string;
    ingredients: RecipeViewIngredient[];
    hasName: boolean;
}

export interface RecipeViewIngredient {
    name: string;
    quantity: string;
    note: string | null;
    grams: number;
}

export interface RecipeViewStep {
    num: number;
    desc: string;
    img: string;
}

export interface GroceryList {
    id: number;
    accountId: number;
    name: string;
    createdAt: string;
    itemsCount: number;
}

export interface GroceryListView {
    id: number;
    name: string;
    createdAt: string;
    items: GroceryListViewItem[];
}

export interface GroceryListViewItem {
    id: number;
    name: string;
    quantity: string;
    quantityWithUnit: string;
    note: string | null;
    isBought: boolean;
    unitId: number;
    grams: number | null;
    recipes: GroceryListViewRecipe[];
}

export interface GroceryListViewRecipe {
    id: number;
    name: string;
    quantity: string;
}

export interface Account {
    id: number;
    name: string;
    email: string;
    avatarUrl: string;
    multiAccountId: number;
    isContentManager: boolean;
    activeSubscription?: Subscription;
    joiningDate: string;
}

export interface Subscription {
    id: number;
    accountId: number;
    name: string;
    startAt: string;
    endAt: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    isAutoRenewal: boolean;
    offer?: SubscriptionOffer;
}

export interface PaymentMethod {
    id: number;
    accountId: number;
    name: string;
    isActive: boolean;
    createdAt: string;
}

export interface SubscriptionOffer {
    id: number;
    code: string;
    fullPeriodPrice: number;
    fullPeriodLength: number;
    discountPeriodPrice: number;
    discountPeriodLength: number;
    name: string;
    description: string;
    source: string;
}

export interface SignInData {
    type: string;
}

export interface SimpleSignInData extends SignInData {
    email: string;
    password: string;
    code?: string;
}

export interface GoogleSignInData extends SignInData {
    googleJwtToken: string;
}

export interface YandexSignInData extends SignInData {
    OAuthToken: string;
}

export interface VkTokenExchangeParams {
    deviceId: string;
    state: string;
    clientId: string;
    redirectUri: string;
    codeVerifier: string;
    code: string;
}

export interface VkSignInData extends SignInData {
    tokenExchangeData: VkTokenExchangeParams;
}

export const SIGN_IN_DATA_TYPES = {
    simple: "Simple",
    vk: "Vk",
    yandex: "Yandex",
    google: "Google"
};