import axios, {AxiosResponse} from "axios";
import {Account, PaymentMethod, Response, SignInData, SignInResult, SimpleSignInData} from "./models";
import {convertKeysToPascalCase} from "../helpers/caseConvertion";

const AccountBaseUrl = `${process.env.REACT_APP_API_URL}/Account`;

const sendSignInCode = async (email: string) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<any>>, string>(
            `${AccountBaseUrl}/SignIn/SendAuthCode`,
            email,
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const signIn = async (signInData: SignInData) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<SignInResult>>, SignInData>(
            `${AccountBaseUrl}/SignIn`,
            convertKeysToPascalCase(signInData)
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const hello = async () => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<Account>>>(
            `${AccountBaseUrl}/Hello`
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const getAccount = async () => {
    try {
        const response = await axios.get<any, AxiosResponse<Response<Account>>>(
            `${AccountBaseUrl}`
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const getPaymentMethods = async () => {
    try {
        const response = await axios.get<any, AxiosResponse<Response<PaymentMethod[]>>>(
            `${AccountBaseUrl}/Payments/GetMethods`
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const updateSubscriptionRenewalFlag = async (subscriptionId: number, autoRenewal: boolean) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<{}>>>(
            `${AccountBaseUrl}/Subscription/UpdateAutoRenewalFlag?subscriptionId=${subscriptionId}&autoRenewal=${autoRenewal}`,
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const updatePaymentMethodIsActiveFlag = async (paymentMethodId: number, status: boolean) => {
    try {
        const response = await axios.post<any, AxiosResponse<Response<{}>>>(
            `${AccountBaseUrl}/Payments/TurnOnOffPaymentMethod?paymentMethodId=${paymentMethodId}&status=${status}`,
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export {
    sendSignInCode,
    signIn,
    getAccount,
    getPaymentMethods,
    updateSubscriptionRenewalFlag,
    updatePaymentMethodIsActiveFlag,
    hello
};