import AppContainer from "../../components/appContainer";
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Stack,
    Switch,
    Typography
} from "@mui/material";
import {AccountBalance, AccountBalanceWalletSharp, Error} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {green} from "@mui/material/colors";
import {useQuery} from "react-query";
import {
    getAccount,
    getPaymentMethods,
    updatePaymentMethodIsActiveFlag,
    updateSubscriptionRenewalFlag
} from "../../api/account";
import {Account, PaymentMethod} from "../../api/models";
import React, {useCallback, useState} from "react";
import moment from "moment/moment";
import {formatDate, formatTime} from "../../helpers/dates";


interface SubscriptionSectionParams {
    account: Account;
}

const SubscriptionSection = ({account}: SubscriptionSectionParams) => {
    const hasSubscription = account.activeSubscription !== null;
    const subscription = account.activeSubscription!!;

    const [isRenewalActive, setIsRenewalActive] = useState(!!account.activeSubscription?.isAutoRenewal);
    const handleChangeRenewal = useCallback(async () => {
        const resp = await updateSubscriptionRenewalFlag(subscription.id, !isRenewalActive);
        if (resp.success) {
            setIsRenewalActive(!isRenewalActive);
        }

    }, [isRenewalActive, subscription]);

    return (
        <Stack direction="column" alignItems="left" justifyContent="left" alignContent="left">
            <Typography variant="h5" component="h2" textAlign="left">
                Ваша активная подписка
            </Typography>

            <Box mt={2}/>
            <Container maxWidth="sm" sx={{p: 0, ml: 0}}>
                <Card variant="outlined" sx={{borderRadius: 5}}>
                    {hasSubscription &&
                        <CardContent>
                            <Typography variant="body1" fontWeight="bold" fontSize="16px">
                                Подписка "{subscription.name}"
                            </Typography>

                            <Typography variant="body1" fontSize="16px">
                                {Math.round(subscription.offer?.fullPeriodPrice || 0)} руб.
                                / {subscription.offer?.fullPeriodLength} дн.
                            </Typography>

                            <Typography variant="body2" color="gray" fontSize="14px">
                                Оплачено {formatDate(subscription.startAt)}
                            </Typography>

                            <Box mt={1}/>
                            <Divider sx={{margin: "0 -16px"}}/>
                            <Box mt={1}/>

                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="column">
                                    <Typography variant="body1" fontSize="18px" textAlign="left">
                                        Автопродление подписки
                                    </Typography>

                                    <Typography variant="body2" fontSize="13px" textAlign="left">
                                        {isRenewalActive &&
                                            <>
                                                Сейчас опция автопродления включена и средства автоматически списываются
                                                с подключенного платежного средства. Чтобы это отключить, нажмите на
                                                переключатель
                                                справа.
                                                Текущая подписка останется активной до конца оплаченного срока.
                                            </>
                                        }

                                        {!isRenewalActive &&
                                            <>
                                                Вы можете включить эту опцию, чтобы средства за подписку списывались
                                                автоматически.
                                            </>
                                        }
                                    </Typography>
                                </Stack>

                                <Switch color="secondary" checked={isRenewalActive}
                                        onChange={handleChangeRenewal}/>
                            </Stack>

                            <Box mt={1}/>
                            <Divider sx={{margin: "0 -16px"}}/>
                            <Box mt={1}/>

                            <Typography variant="body1" fontSize="15px" textAlign="left" gutterBottom>
                                Подписка действует по <b>{formatDate(subscription.endAt)}</b> (включительно).
                            </Typography>

                            <Typography variant="body1" fontSize="14px" textAlign="left">
                                По вопросам, связанным с этой подпиской, вы можете обращаться по эл. почте <a
                                href="mailto:support@cookhaus.guru">support@cookhaus.guru</a> или в телеграм-аккаунт <a
                                href="https://t.me/cookhaus_support">@cookhaus_support</a>.
                            </Typography>
                        </CardContent>
                    }

                    {!hasSubscription &&
                        <CardContent>
                            <Typography variant="body1" fontSize="16px">
                                У вас нет активной подписки :(
                            </Typography>
                        </CardContent>
                    }
                </Card>
            </Container>
        </Stack>
    );
};

interface PaymentMethodsSectionParams {
    paymentMethods: PaymentMethod[];
}

const PaymentMethodsSection = ({paymentMethods}: PaymentMethodsSectionParams) => {
    if (paymentMethods.length === 0) {
        return (<></>);
    }

    return (
        <Stack direction="column" alignItems="left" justifyContent="left" alignContent="left">
            <Typography variant="h5" component="h2" textAlign="left">
                Привязанные платежные средства
            </Typography>

            <Box mt={2}/>
            <Grid2 container columns={{xs: 1, md: 3}} spacing={2}>
                {paymentMethods.sort((a, b) => a.id - b.id).map(pm => (
                    <Grid2 xs={1} key={pm.id}>
                        <PaymentMethodCard paymentMethod={pm}/>
                    </Grid2>
                ))}
            </Grid2>
        </Stack>
    );
};

interface PaymentMethodCardParams {
    paymentMethod: PaymentMethod;
}

const PaymentMethodCard = ({paymentMethod}: PaymentMethodCardParams) => {
    const [isActive, setIsActive] = useState(paymentMethod.isActive);
    const handleTurnOnOff = useCallback(async () => {
        const resp = await updatePaymentMethodIsActiveFlag(paymentMethod.id, !isActive);
        if (resp.success) {
            setIsActive(!isActive);
        }

    }, [isActive, paymentMethod]);


    return (
        <Card sx={{borderRadius: 5}} variant="outlined">
            <CardHeader
                title={paymentMethod.name}
                subheader={`Подключено ${formatDate(paymentMethod.createdAt)} в ${formatTime(paymentMethod.createdAt)}`}
                avatar={<Avatar sx={{bgcolor: green[300]}} variant="rounded"><AccountBalance
                    fontSize="large"/></Avatar>}
            />
            <CardActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center"
                       width="100%">
                    <Typography variant="body1" fontSize="15px">
                        Регулярные списания
                    </Typography>

                    <Switch color="secondary" checked={isActive} onChange={handleTurnOnOff}/>
                </Stack>
            </CardActions>
        </Card>
    );
};

const SubManagePage = () => {
    const {data: account, isLoading: accountIsLoading} = useQuery("account", async () => {
        return (await getAccount()).result;
    });

    const {data: paymentMethods, isLoading: paymentMethodsIsLoading} = useQuery("paymentMethods", async () => {
        return (await getPaymentMethods()).result || [];
    });

    const isLoading = accountIsLoading || paymentMethodsIsLoading;

    return (
        <AppContainer>
            {isLoading &&
                <Stack width="100%" alignItems="center" mt={3}>
                    <Box component="div" className="dots-flashing"/>
                </Stack>
            }

            {!isLoading && account == null &&
                <Typography variant="body1" fontSize="24px">
                    <Error/> Что-то пошло не так, обратитесь в поддержку.
                </Typography>
            }

            {!isLoading && account != null &&
                <>
                    <Box mt={3}/>
                    <SubscriptionSection account={account!!}/>

                    <Box mt={3}/>
                    <PaymentMethodsSection paymentMethods={paymentMethods || []}/>
                </>
            }
        </AppContainer>
    );
};

export default SubManagePage;
