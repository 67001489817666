import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const YandexOauthPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isFromQuiz = searchParams.has('isFromQuiz');

    useEffect(() => {
        const originUrl = isFromQuiz
            ? process.env.REACT_APP_YANDEX_QUIZ_REDIRECT
            : process.env.REACT_APP_YANDEX_MY_REDIRECT;

        // @ts-ignore
        window.YaSendSuggestToken(originUrl);
    }, [isFromQuiz]);

    return (
        <>Подождите, сейчас мы вас перенаправим...</>
    );
};

export default YandexOauthPage;